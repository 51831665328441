// ===========================================================================
// Taken from Academic
// ===========================================================================

import { FC, ReactNode, useEffect, useRef, useState } from "react";
type CollapsibleProps = {
  /**
   * Boolean value to control when the collapsed or not.
   *
   * True to Collapse, False to Expand.
   */
  collapsed: boolean;
  /**
   * Content of the collapsible component.
   */
  children: ReactNode;
  /**
   * Extend the classname of the collapsible. (Attached to the third div)
   *
   * By default it already has `absolute`.
   */
  className?: string;
  /**
   * Height of the collapsible when collapsed.
   * @defaultValue "0px"
   */
  collapsedHeight?: string;
  /**
   * True to enable dynamic height so the collapsible can adapt to the child
   * component's height if it changes. It is less performant but useful for
   * situations where the content is a dynamic list or gets populated after
   * a few seconds after mounting.
   *
   * By default it is false.
   */
  dynamicHeight?: boolean;
};

/**
 * A headless animated collapsible component. AntDesign implementation of this
 * expands from the bottom of the button. With this, you can expand/collapse
 * based on your own button using `isShown`.
 *
 * Main usecase is for the **Calendar**.
 * Can be improved for other usecases.
 *
 * Current Implementation (Rooms for Improvement):
 * - **Vertical Only.** Can be improved for later usecases like horizontal.
 * - **No Origin.** No implementation for this yet, but can be useful if you want to
 * make it start expanding from the top, bottom, right, or left. By default it sticks to the top and right.
 */
const Collapsible: FC<CollapsibleProps> = ({
  collapsed,
  children,
  className,
  collapsedHeight = "0px",
  dynamicHeight: hasDynamicHeight = false
}) => {
  const [height, setHeight] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement>(null);

  /**
   * If dynamicHeight is enabled, track the height of the content so we
   * can use that as the expanded height of the collapsible. Works best for
   * dynamic content.
   */
  useEffect(() => {
    if (!contentRef.current) return;
    setHeight(contentRef.current.clientHeight);
    if (!hasDynamicHeight) return () => null;
    const resizeObserver = new ResizeObserver(() => {
      // Do what you want when it changes
      if (contentRef?.current?.clientHeight) setHeight(contentRef.current.clientHeight);
    });
    resizeObserver.observe(contentRef.current);
    return () => resizeObserver.disconnect();
  }, [hasDynamicHeight]);
  return (
    /**
     * 1. First div is necessary so the second div does not shrink.
     * 2. Second div is for clipping and resizing the collapsible.
     * 3. Third div the content and is absolute so you can get its height even when not visible.
     *    The ref on this is for getting the height, which important for the animation.
     */
    <div data-sentry-component="Collapsible" data-sentry-source-file="collapsible.tsx">
      <div className="relative overflow-hidden duration-300 ease-out" style={{
        height: collapsed ? collapsedHeight : height
      }}>
        <div ref={contentRef} className={`absolute ${className}`}>
          {children}
        </div>
      </div>
    </div>
  );
};
export default Collapsible;