import { CopyButton } from "@/components/client/button";

/**
 * Props for the FieldDisplayOnly component.
 */
type FieldDisplayOnlyProps = {
  /**
   * Optional classname for hte container.
   */
  className?: string;
  /**
   * Copied value is not affected by the trim value.
   * The actual value is always copied.
   */
  enableCopy?: boolean;
  /**
   * Label for the field.
   */
  label: string;
  /**
   * Optional classname for the value.
   */
  valueClassName?: string;
  /**
   * Number of characters to trim the value at.
   * This could be a positive or a negative number.
   *
   * A negative number will trim from the end.
   * A positive number will trim from the start.
   *
   * This will only affect the display value and not the actual value.
   */
  trim?: number;
  /**
   * Value for the field.
   */
  value?: string | number;
};

/**
 * A simple reusable component for
 * rendering a field with a "label" and a "value"
 *
 * @example
 * **Date:** April 14, 2024
 */
export function FieldDisplayOnly(props: FieldDisplayOnlyProps) {
  function trim(args: {
    /**
     * A positive or negative number.
     *
     * A negative number will trim from the end.
     *
     * A positive number will trim from the start.
     */
    length: number;
    /**
     * The value to trim.
     */
    value: string;
  }) {
    const length = Math.abs(args.length);
    if (length === 0) {
      return args.value;
    }
    return args.length < 0 ? (length < args.value.length ? "***" : "") + args.value.slice(args.length) : args.value.slice(length);
  }
  return <div className={props.className} data-sentry-component="FieldDisplayOnly" data-sentry-source-file="field-display-only.tsx">
      <span className="text-sm font-semibold text-tpl-navy">
        {props.label}:
      </span>
      <span className="text-sm text-tpl-navy">
        {" " + (props.trim ? trim({
        length: props.trim,
        value: props.value?.toString() || ""
      }) : props.value)}
      </span>
      {props.enableCopy && !!props.value && <CopyButton value={props.value?.toString() || ""} />}
    </div>;
}